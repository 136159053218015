/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.brown-section {
  background-color: #713229;
  position: sticky;
  top: 0;
  z-index: 9999;
}

nav {
display: flex;
justify-content: space-between;align-items: center;height: 70px;padding: 0px 16px;
}


.nav li {
  font-size: 16px;margin-left: 16px;
  list-style: none;
    display: inline;
    vertical-align: middle;
  
}

.nav li:last-child {
  position: relative;
}
.nav{padding: 0;}
.nav img {
  width: 38px;
  border-radius: 20px;vertical-align: middle;
}

.nav li a {
  text-decoration: none;
  color: #fff;
  /* text-align: center; */
}
.nav {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0;
}

.nav li {
  padding: 2px 15px;
}

.nav a {
  text-decoration: none;
  color: inherit;
}

.active-nav {
  background-color: aqua;
  border-radius: 8px;
}

.active-nav a {
  color: black !important;
}

